import React, {useState} from 'react';
import moment from 'moment';
import useReactRouter from 'use-react-router';
import fp from 'lodash/fp';
import {useTranslation} from 'react-i18next';
import {useQuery, useMutation} from '@apollo/react-hooks';
import {makeStyles} from '@material-ui/core/styles';
import gql from 'graphql-tag';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import BusinessIcon from '@material-ui/icons/Business';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ViewListIcon from '@material-ui/icons/ViewList';

import Table from '../components/Table';
import Select from '../components/Select';
import BuildingModel from '../model/building';
import {renderBuildingAddress} from './Gateways/GatewaysInOperation';
import {TelegramsDialog} from '../components/TelegramsDialog';
import OrganizationBilling from './Organization/Billing';
import { Route } from 'react-router-dom';

const GET_ORGANIZATION = gql`
  query getOrganization($id: ID!) {
    organizations(ids: [$id]) {
      id
      eras_id
      name
      street
      house_number
      city
      postalcode
      phone
      email
      once_id
      updated_at
      buildings {
        id
        street
        house_number
        city
        alternate_address
        postalcode
        eras_id
      }
      users {
        id
        email
        role
        profile {
          id
          first_name
          last_name
          phone
        }
      }
      gateways {
        id
        iccid
        imei
        serial_number
        comment
        manufacturer
        model
        last_reading
        building {
          id
          street
          house_number
          city
          postalcode
        }
        file_prefix
      }
      ftpConfigs {
        id
        user
        host
        port
        setup
        folder
      }
    }
  }
`;

const ADD_BUILDING = gql`
  mutation addBuildingToOrganization($buildingId: ID!, $organizationId: ID!) {
    addBuildingToOrganization(
      buildingId: $buildingId
      organizationId: $organizationId
    )
  }
`;

const DELETE_BUILDING = gql`
  mutation deleteBuildingFromOrganization(
    $buildingId: ID!
    $organizationId: ID!
  ) {
    deleteBuildingFromOrganization(
      buildingId: $buildingId
      organizationId: $organizationId
    )
  }
`;

const ADD_USER = gql`
  mutation addUser(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $phone: String
    $role: Int!
    $organizationId: ID!
  ) {
    addUser(
      email: $email
      password: $password
      role: $role
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      organizationId: $organizationId
    ) {
      id
    }
  }
`;

const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

const GET_BUILDINGS = gql`
  query getBuildings {
    buildings {
      id
      eras_id
      street
      house_number
      city
      postalcode
    }
  }
`;

const DELETE_GATEWAY = gql`
  mutation deleteGateway($id: ID!) {
    deleteGateway(id: $id) {
      id
    }
  }
`;

const ADD_FTP_CONFIG = gql`
  mutation addFtpConfig(
    $user: String!
    $password: String!
    $host: String!
    $port: Int!
    $setup: String!
    $folder: String!
    $organizationId: ID!
  ) {
    addFtpConfig(
      user: $user
      password: $password
      host: $host
      port: $port
      setup: $setup
      folder: $folder
      organizationId: $organizationId
    ) {
      id
      user
      host
      port
      setup
      folder
    }
  }
`;

const UPDATE_FTP_CONFIG = gql`
  mutation updateFtpConfig(
    $id: ID!
    $user: String!
    $password: String
    $host: String!
    $port: Int!
    $setup: String!
    $folder: String!
    $organizationId: ID!
  ) {
    updateFtpConfig(
      id: $id
      user: $user
      password: $password
      host: $host
      port: $port
      setup: $setup
      folder: $folder
      organizationId: $organizationId
    ) {
      id
      user
      host
      port
      setup
      folder
    }
  }
`;

const DELETE_FTP_CONFIG = gql`
  mutation deleteFtpConfig($id: ID!) {
    deleteFtpConfig(id: $id) {
      id
    }
  }
`;

const BuildingsSelect = (props) => {
  const {t} = useTranslation(['organization']);
  const {loading, error, data} = useQuery(GET_BUILDINGS, {});

  if (error) {
    return null;
  }

  const buildings = loading
    ? []
    : data.buildings.map((building) => {
        return {
          label: BuildingModel.label(building),
          value: building.id,
        };
      });

  return (
    <Select
      menuPlacement="top"
      inputId="building-id"
      options={buildings}
      onChange={({value}) => {
        props.onRowDataChange({
          ...props.rowData,
          id: value,
        });
      }}
      value={fp.find({value: props.rowData.id}, buildings)}
      TextFieldProps={{
        required: false,
        label: t('organization:Building'),
        margin: 'normal',
        name: 'building',
      }}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  titleIcon: {
    verticalAlign: 'text-bottom',
    marginRight: 5,
  },
}));

const BuildingsTable = ({match, organizationId, buildings}) => {
  const {t} = useTranslation(['organization', 'building']);
  const {history} = useReactRouter();
  const [addBuilding, {loading: addBuildingLoading, error: addBuildingError}] =
    useMutation(ADD_BUILDING, {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_ORGANIZATION,
          variables: {
            id: match.params.id,
          },
        },
      ],
    });
  const [
    deleteBuilding,
    {loading: deleteBuildingLoading, error: deleteBuildingError},
  ] = useMutation(DELETE_BUILDING, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ORGANIZATION,
        variables: {
          id: match.params.id,
        },
      },
    ],
  });
  const isLoading = addBuildingLoading || deleteBuildingLoading;

  if (addBuildingError || deleteBuildingError) {
    return <div>Error</div>;
  }

  return (
    <Table
      title={t('organization:Buildings')}
      tableId={`organization-${organizationId}-buildings`}
      isLoading={isLoading}
      columns={[
        {
          title: t('building:ID'),
          field: 'id',
          type: 'numeric',
          defaultSort: 'desc',
          editable: false,
        },
        {
          title: t('building:Street'),
          field: 'street',
          editable: 'onAdd',
          editComponent: BuildingsSelect,
          width: 400,
        },
        {
          title: t('building:Number'),
          field: 'house_number',
          editable: 'never',
        },
        {title: t('building:City'), field: 'city', editable: 'never'},
        {
          title: t('building:Zipcode'),
          field: 'postalcode',
          editable: 'never',
        },
        {
          title: t('building:ID1'),
          field: 'eras_id',
          editable: 'never',
        },
        {
          title: t('building:Alternate Address'),
          field: 'alternate_address',
          editable: 'never',
        },
      ]}
      data={isLoading ? [] : buildings}
      options={{
        sorting: true,
        pageSize: 5,
      }}
      onRowClick={(event, rowData) => {
        history.push(`/buildings/${rowData.id}`);
      }}
      editable={{
        onRowAdd: async (newData) => {
          await addBuilding({
            variables: {
              buildingId: newData.id,
              organizationId,
            },
          });
        },
        onRowDelete: async (oldData) => {
          await deleteBuilding({
            variables: {
              buildingId: oldData.id,
              organizationId,
            },
          });
        },
      }}
    />
  );
};

const UsersTable = ({match, organizationId, users}) => {
  const {t} = useTranslation(['organization', 'user']);
  const {history} = useReactRouter();
  const [addUser, {error: addUserError, loading: addUserLoading}] = useMutation(
    ADD_USER,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_ORGANIZATION,
          variables: {
            id: match.params.id,
          },
        },
      ],
    },
  );
  const [deleteUser, {error: deleteUserError, loading: deleteUserLoading}] =
    useMutation(DELETE_USER, {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_ORGANIZATION,
          variables: {
            id: match.params.id,
          },
        },
      ],
    });
  const isLoading = addUserLoading || deleteUserLoading;

  if (addUserError || deleteUserError) {
    return <div>Error</div>;
  }

  return (
    <Table
      title={t('organization:Users')}
      tableId={`organization-${organizationId}-users`}
      isLoading={isLoading}
      columns={[
        {
          title: t('user:ID'),
          field: 'id',
          type: 'numeric',
          defaultSort: 'desc',
          editable: 'never',
        },
        {title: t('user:E-mail'), field: 'email'},
        {
          title: t('user:First Name'),
          field: 'profile.first_name',
        },
        {
          title: t('user:Last Name'),
          field: 'profile.last_name',
        },
        {
          title: t('user:Phone'),
          field: 'profile.phone',
        },
        {
          title: t('user:Password'),
          field: 'password',
          render: () => '*************',
        },
        {
          title: t('user:Role'),
          field: 'role',
          lookup: t('user:roles'),
        },
      ]}
      data={isLoading ? [] : users}
      options={{
        sorting: true,
        pageSize: 5,
      }}
      onRowClick={(event, rowData) => {
        history.push(`/users/${rowData.id}`);
      }}
      editable={{
        onRowAdd: async (newData) => {
          await addUser({
            variables: {
              email: newData.email,
              password: newData.password,
              firstName: newData.profile.first_name,
              lastName: newData.profile.last_name,
              phone: newData.profile.phone,
              role: Number(newData.role),
              organizationId,
            },
          });
        },
        onRowDelete: async (oldData) => {
          await deleteUser({
            variables: {
              id: oldData.id,
            },
          });
        },
      }}
    />
  );
};

const GatewaysTable = ({match, gateways, organizationId}) => {
  const {t} = useTranslation(['organization', 'gateway']);
  const {history} = useReactRouter();
  const [
    deleteGateway,
    {error: deleteGatewayError, loading: deleteGatewayLoading},
  ] = useMutation(DELETE_GATEWAY, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ORGANIZATION,
        variables: {
          id: match.params.id,
        },
      },
    ],
  });
  const [chosenGateway, setChosenGateway] = useState(null);
  const onTelegramsDialogClose = () => setChosenGateway(null);
  const isLoading = deleteGatewayLoading;

  if (deleteGatewayError) {
    return <div>Error</div>;
  }

  return (
    <React.Fragment>
      {!!chosenGateway ? (
        <TelegramsDialog
          gateway={chosenGateway}
          onClose={onTelegramsDialogClose}
        />
      ) : null}
      <Table
        title={t('organization:Gateways')}
        tableId={`organization-${organizationId}-gateways`}
        isLoading={isLoading}
        columns={[
          {
            title: t('gateway:ID'),
            field: 'id',
            type: 'numeric',
            defaultSort: 'desc',
            editable: 'never',
          },
          {title: t('gateway:Serial Number'), field: 'serial_number'},
          {title: t('gateway:IMEI'), field: 'imei'},
          {title: t('gateway:ICCID'), field: 'iccid'},
          {title: t('gateway:Manufacturer'), field: 'manufacturer'},
          {title: t('gateway:Model'), field: 'model'},
          {
            title: t('gateway:Last Reading'),
            field: 'last_reading',
            type: 'datetime',
            editable: 'never',
            render: (rowData) =>
              rowData.last_reading
                ? moment(rowData.last_reading).fromNow()
                : 'Never',
          },
          {title: t('gateway:Building Address'), render: renderBuildingAddress},
          {title: t('gateway:Comment'), field: 'comment'},
          {
            title: t('gateway:File prefix'),
            field: 'file_prefix',
          },
        ]}
        data={isLoading ? [] : gateways}
        options={{
          sorting: true,
          pageSize: 5,
        }}
        onRowClick={(event, rowData) => {
          history.push(`/gateways/${rowData.id}`);
        }}
        actions={[
          {
            icon: AddBoxIcon,
            tooltip: 'Add',
            isFreeAction: true,
            onClick: () => {
              history.push(`/gateways/new?organizationId=${match.params.id}`);
            },
          },
          (rowData) => ({
            icon: ViewListIcon,
            tooltip: t('gateway:Show telegrams'),
            isFreeAction: false,
            onClick: () => {
              setChosenGateway(rowData);
            },
            disabled: !fp.get('last_reading', rowData),
          }),
        ]}
        editable={{
          onRowDelete: async (oldData) => {
            await deleteGateway({
              variables: {
                id: oldData.id,
              },
            });
          },
        }}
      />
    </React.Fragment>
  );
};

const FtpConfigsTable = ({match, ftpConfigs, organizationId}) => {
  const {t} = useTranslation(['organization', 'ftpConfig']);
  const [addFtpConfig] = useMutation(ADD_FTP_CONFIG, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ORGANIZATION,
        variables: {
          id: match.params.id,
        },
      },
    ],
  });
  const [updateFtpConfig] = useMutation(UPDATE_FTP_CONFIG);
  const [
    deleteFtpConfig,
    {error: deleteFtpConfigError, loading: deleteFtpConfigLoading},
  ] = useMutation(DELETE_FTP_CONFIG, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ORGANIZATION,
        variables: {
          id: match.params.id,
        },
      },
    ],
  });
  const isLoading = deleteFtpConfigLoading;

  if (deleteFtpConfigError) {
    return <div>Error</div>;
  }

  return (
    <Table
      title={t('organization:FTP Configurations')}
      tableId={`organization-${organizationId}-ftp-configurations`}
      isLoading={isLoading}
      columns={[
        {
          title: t('ftpConfig:ID'),
          field: 'id',
          type: 'numeric',
          defaultSort: 'desc',
          editable: 'never',
        },
        {title: t('ftpConfig:User'), field: 'user'},
        {
          title: t('ftpConfig:Password'),
          field: 'password',
          render: () => '*************',
        },
        {title: t('ftpConfig:Host'), field: 'host'},
        {title: t('ftpConfig:Port'), field: 'port'},
        {
          title: t('ftpConfig:Setup'),
          field: 'setup',
          lookup: {
            engelmann: 'engelmann',
            webdyn: 'webdyn',
            DSP: 'DSP',
            'DSP-Qundis': 'DSP-Qundis',
            'DSP-Engelmann': 'DSP-Engelmann',
          },
        },
        {title: t('ftpConfig:Folder'), field: 'folder'},
      ]}
      data={isLoading ? [] : ftpConfigs}
      options={{
        sorting: true,
        pageSize: 5,
      }}
      editable={{
        onRowAdd: async (newData) => {
          await addFtpConfig({
            variables: {
              user: newData.user,
              password: newData.password,
              host: newData.host,
              port: Number(newData.port),
              setup: newData.setup,
              folder: newData.folder || '',
              organizationId: match.params.id,
            },
          });
        },
        onRowUpdate: async (newData, oldData) => {
          await updateFtpConfig({
            variables: {
              id: oldData.id,
              user: newData.user,
              host: newData.host,
              port: Number(newData.port),
              setup: newData.setup,
              folder: newData.folder || '',
              organizationId: match.params.id,
              ...(!!newData.password && {password: newData.password}),
            },
          });
        },
        onRowDelete: async (oldData) => {
          await deleteFtpConfig({
            variables: {
              id: oldData.id,
            },
          });
        },
      }}
    />
  );
};

const Content = ({ match }) => {
  const {t} = useTranslation(['organization']);
  const {loading, error, data} = useQuery(GET_ORGANIZATION, {
    variables: {
      id: match.params.id,
    },
  });
  const classes = useStyles();
  const isLoading = loading;

  if (error) {
    return <div>Error</div>;
  }

  if (isLoading) {
    return <LinearProgress />;
  }

  const organization = fp.first(data.organizations);

  if (!organization) {
    return <div>{t('organization:Organization not found')}</div>;
  }

  return (
    <div className={classes.root}>
      <Box>
        <Table
          title={
            <div>
              <BusinessIcon className={classes.titleIcon} />
              {organization.name}
            </div>
          }
          isLoading={isLoading}
          columns={[
            {
              title: t('organization:ID'),
              field: 'id',
              type: 'numeric',
              defaultSort: 'desc',
            },
            {title: t('organization:Name'), field: 'name'},
            {title: t('organization:Street'), field: 'street'},
            {title: t('organization:Number'), field: 'house_number'},
            {title: t('organization:City'), field: 'city'},
            {title: t('organization:ZIP code'), field: 'postalcode'},
            {title: t('organization:Phone'), field: 'phone'},
            {title: t('organization:E-mail'), field: 'email'},
            {title: t('organization:1NCE ID'), field: 'once_id'},
            {
              title: t('organization:ID1'),
              field: 'eras_id',
            },
            {
              title: t('organization:Update Date'),
              field: 'updated_at',
              render: (rowData) => moment.utc(rowData.updated_at).format('LL'),
            },
          ]}
          data={isLoading ? [] : data.organizations}
          options={{
            sorting: false,
            search: false,
            toolbar: true,
            paging: false,
          }}
        />
      </Box>

      <Box>
        <BuildingsTable
          buildings={organization.buildings}
          organizationId={organization.id}
          match={match}
        />
      </Box>

      <Box>
        <UsersTable
          users={organization.users}
          organizationId={organization.id}
          match={match}
        />
      </Box>

      <Box>
        <GatewaysTable
          gateways={organization.gateways}
          organizationId={organization.id}
          match={match}
        />
      </Box>

      <Box>
        <FtpConfigsTable
          ftpConfigs={organization.ftpConfigs}
          organizationId={organization.id}
          match={match}
        />
      </Box>
    </div>
  );
}

export default function Organization({ match }) {
  return (
    <React.Fragment>
      <Route exact path={`${match.path}`} component={Content} />
      <Route path={`${match.path}/billing`} component={OrganizationBilling} />
    </React.Fragment>
  );
}
