import RouterIcon from '@material-ui/icons/Router';
import moment from 'moment';
import React from 'react';

import Table from "../../components/Table";

export default function BuildingSensors({ classes, building, isLoading, t }) {
  return building.id ? (
    <Table
      title={
        <div>
          <RouterIcon className={classes.titleIcon} />
          {t('building:Sensors')}
        </div>
      }
      tableId={`building-${building.id}-sensors`}
      isLoading={isLoading}
      columns={[
        {
          title: t('building:ID'),
          field: 'id',
          type: 'numeric',
          defaultSort: 'desc',
        },
        { title: t('building:Serial Number'), field: 'serial_number' },
        { title: t('building:ID1'), field: 'eras_id' },
        { title: t('building:ID4'), field: 'eras_number' },
        {
          title: t('building:Type'),
          field: 'original_type',
        },
        {
          title: t('building:Status'),
          field: 'status',
          lookup: t('sensor:statuses'),
        },
        { title: t('building:Name'), field: 'name' },
        { title: t('building:Factor'), field: 'factor', type: 'numeric' },
        { title: t('building:Comment'), field: 'comment' },
        {
          title: t('building:Installed Date'),
          field: 'installed_at',
          type: 'datetime',
          render: (rowData) => moment(rowData.installed_at).format('LL'),
        },
        {
          title: t('building:Uninstalled Date'),
          field: 'removed_at',
          type: 'datetime',
          render: (rowData) =>
            rowData.removed_at
              ? moment(rowData.removed_at).format('LL')
              : '',
        },
      ]}
      data={building.sensors}
      options={{
        sorting: true,
        search: true,
        toolbar: true,
        pageSize: 5,
        pageSizeOptions: [5, 10, 25],
      }}
    />
  ) : null;
}