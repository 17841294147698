// @format

const TYPE_ERAS_IMPORT = 1;
const TYPE_SK_SOFT_EXPORT = 2;
const TYPE_SK_SOFT_IMPORT = 3;

export default {
  TYPE_ERAS_IMPORT,
  TYPE_SK_SOFT_EXPORT,
  TYPE_SK_SOFT_IMPORT,
};
