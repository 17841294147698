import React from 'react';
import {useLazyQuery, useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {Route} from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import {useMe} from '../reducers/me';
import GatewayForm from './Gateway/GatewayForm.js';

const GET_EDIT_GATEWAY = gql`
  query getEditGateway($id: ID!) {
    singleGateway(id: $id) {
      id
      iccid
      imei
      serial_number
      manufacturer
      model
      comment
      building {
        id
      }
      organization {
        id
      }
      file_prefix
    }
  }
`;

const GET_BUILDINGS = gql`
  query getBuildings($organizationId: ID) {
    buildings(organizationId: $organizationId) {
      id
      street
      house_number
      city
      postalcode
      eras_id
    }
  }
`;

const GET_ORGANIZATIONS = gql`
  query getOrganizations {
    organizations {
      id
      name
    }
  }
`;

const Content = ({match}) => {
  const [useMeData] = useMe();
  const gatewayId = match.params.id;
  const isEditPage = !!gatewayId;
  const [
    getBuildings,
    {loading: buildingsLoading, error: buildingsError, data: buildingsData},
  ] = useLazyQuery(GET_BUILDINGS, {});
  const {
    data: organizationsData,
    loading: organizationsLoading,
    error: organizationsError,
  } = useQuery(GET_ORGANIZATIONS, {});
  const {
    loading: getGatewayLoading,
    error: getGatewayError,
    data: getGatewayData,
  } = useQuery(GET_EDIT_GATEWAY, {
    variables: {
      id: gatewayId,
    },
    skip: !isEditPage,
  });

  if (getGatewayLoading || organizationsLoading) {
    return <CircularProgress />;
  }

  if (buildingsError || getGatewayError || organizationsError) {
    console.error(buildingsError || getGatewayError || organizationsError);
    return <div>Error</div>;
  }

  return <GatewayForm
    useMeData={useMeData}
    getBuildings={getBuildings}
    buildingsLoading={buildingsLoading}
    buildingsData={buildingsData}
    getGatewayData={getGatewayData}
    organizationsData={organizationsData}
    isEditPage={isEditPage}
    gatewayId={gatewayId}
  />;
};

export default function Gateway({match}) {
  return (
    <React.Fragment>
      <Route exact path={match.path} component={Content} />
    </React.Fragment>
  );
}
