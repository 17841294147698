import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {MTableToolbar} from 'material-table';
import {makeStyles} from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import Box from '@material-ui/core/Box';
import Table from '../../components/Table';

const useStyles = makeStyles((theme) => ({
  dataBox: {
    margin: '0 0 1rem 0',
  },
  toolbarWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleIcon: {
    verticalAlign: 'text-bottom',
    marginRight: 5,
  },
  tableToolbar: {
    flexWrap: 'wrap-reverse',
  },
}));

const Info = ({building, apartment, isLoading}) => {
  const classes = useStyles();
  const {t} = useTranslation(['uvi', 'building']);
  return (
    <React.Fragment>
      <Box>
        <Table
          title={
            <div>
              <HomeIcon className={classes.titleIcon} />
              {building.street} {building.house_number} | {apartment.location}
            </div>
          }
          isLoading={isLoading}
          columns={[
            {
              title: t('building:ID'),
              field: 'id',
              type: 'numeric',
              defaultSort: 'desc',
            },
            {title: t('building:Street'), editable: 'never', field: 'street'},
            {
              title: t('building:Number'),
              field: 'house_number',
            },
            {title: t('building:City'), editable: 'never', field: 'city'},
            {
              title: t('building:Zipcode'),
              field: 'postalcode',
            },
            {title: t('building:ID1'), editable: 'never', field: 'eras_id'},
            {
              title: t('building:Alternate Address'),
              field: 'alternate_address',
            },
          ]}
          data={isLoading ? [] : [building]}
          options={{
            sorting: false,
            search: false,
            toolbar: true,
            paging: false,
          }}
          components={{
            Toolbar: (props) => (
              <div className={classes.toolbarWrapper}>
                <MTableToolbar
                  {...props}
                  classes={{root: classes.tableToolbar}}
                />
              </div>
            ),
          }}
        />
      </Box>

      <Box>
        <Table
          isLoading={isLoading}
          columns={[
            {
              title: t('building:ID'),
              field: 'id',
              type: 'numeric',
              defaultSort: 'desc',
            },
            {title: t('building:Eras Number'), field: 'eras_number'},
            {title: t('building:Name'), field: 'eras_name'},
            {title: t('building:Name2'), field: 'eras_name2'},
            {title: t('building:Location'), field: 'location'},
          ]}
          data={[apartment]}
          options={{
            sorting: false,
            search: false,
            toolbar: false,
            paging: false,
          }}
        />
      </Box>
    </React.Fragment>
  );
};

const TypeBuilding = PropTypes.shape({
  street: PropTypes.string.isRequired,
  house_number: PropTypes.string.isRequired,
});

const TypeApartment = PropTypes.shape({
  location: PropTypes.string.isRequired,
});

Info.propTypes = {
  apartment: TypeApartment.isRequired,
  building: TypeBuilding.isRequired,
  isLoading: PropTypes.bool,
};

export default Info;
