import React from 'react';
import fp from 'lodash/fp';
import {useQuery, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import useReactRouter from 'use-react-router';
import {Route} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import BuildingModel from '../model/building';
import ReportForm from './NewReport/ReportForm';
import { useMe } from '../reducers/me';

const GET_EDIT_REPORT = gql`
  query getEditReport($id: ID!) {
    reports(ids: [$id]) {
      id
      reading_date
      set_date
      comment
      operator {
        id
        email
      }
      building {
        id
        street
        house_number
        postalcode
        city
      }
      repeat
      receiver
    }
  }
`;

const GET_NEW_REPORT = gql`
  query getNewReport($organizationId: ID) {
    buildings(ids: [], organizationId: $organizationId) {
      id
      street
      house_number
      city
      postalcode
      set_date
      eras_id
    }
    operators(ids: []) {
      id
      email
    }
    me {
      id
      organization {
        id
        availableExports
      }
    }
  }
`;

const CREATE_REPORT = gql`
  mutation createReport(
    $buildingId: ID!
    $operatorId: ID
    $setDate: DateTime
    $readingDate: DateTime!
    $repeat: Repeat
    $comment: String
    $receiver: Receiver
  ) {
    createReport(
      buildingId: $buildingId
      operatorId: $operatorId
      setDate: $setDate
      readingDate: $readingDate
      repeat: $repeat
      comment: $comment
      receiver: $receiver
    ) {
      id
    }
  }
`;

const UPDATE_REPORT = gql`
  mutation updateReport(
    $id: ID!
    $buildingId: ID!
    $operatorId: ID
    $setDate: DateTime
    $readingDate: DateTime!
    $repeat: Repeat
    $comment: String
    $receiver: Receiver
  ) {
    updateReport(
      id: $id
      buildingId: $buildingId
      operatorId: $operatorId
      setDate: $setDate
      readingDate: $readingDate
      repeat: $repeat
      comment: $comment
      receiver: $receiver
    ) {
      id
    }
  }
`;

const formatBuildingOption = (building) => ({
  label: BuildingModel.label(building),
  value: building.id,
});

const formatOperatorOption = (operator) => ({
  label: operator.email,
  value: operator.id,
});

const Content = ({match}) => {
  const isEditPage = !!match.params.id;
  const [useMeData] = useMe();
  const {loading, error, data} = useQuery(GET_NEW_REPORT, {
    variables: {
      organizationId: useMeData.organizationId,
    },
  });
  const {history} = useReactRouter();
  const {
    loading: getReportLoading,
    error: getReportError,
    data: getReportData,
  } = useQuery(GET_EDIT_REPORT, {
    variables: {
      id: match.params.id,
    },
    skip: !isEditPage,
  });
  const [createReport] = useMutation(CREATE_REPORT, {
    onCompleted: (response) => {
      history.push(`/reports/${response.createReport.id}`);
    },
  });
  const [updateReport, {loading: updateReportLoading}] = useMutation(
    UPDATE_REPORT,
    {
      onCompleted: (response) => {
        history.push(`/reports/${response.updateReport.id}`);
      },
    },
  );
  const report = getReportData ? fp.first(getReportData.reports) : null;

  const isLoading = loading || getReportLoading || updateReportLoading;

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error || getReportError) {
    console.error(error || getReportError);
    return <div>Error</div>;
  }

  const buildings = data.buildings.map(formatBuildingOption);
  const operators = data.operators.map(formatOperatorOption);
  const {availableExports} = data.me.organization;

  return (
    <Grid container>
      <Grid item xs={12}>
        <ReportForm
          isEditPage={isEditPage}
          report={report}
          createReport={createReport}
          updateReport={updateReport}
          match={match}
          buildings={buildings}
          operators={operators}
          availableExports={availableExports}
        />
      </Grid>
    </Grid>
  );
};

export default function NewReport({match}) {
  return (
    <React.Fragment>
      <Route exact path={match.path} component={Content} />
    </React.Fragment>
  );
}
