// @format

const STATUS_OK = 1;
const STATUS_OUT_OF_SYNC = 2;
const STATUS_MISSING_DATA = 3;
const STATUS_WITHOUT_ORGANIZATION = 4;

const GATEWAY_STAT_STATUSES = [
	STATUS_OK,
	STATUS_OUT_OF_SYNC,
	STATUS_MISSING_DATA,
];
const GATEWAY_STAT_STATUS_COLORS = {
	[STATUS_OK]: 'Green',
	[STATUS_OUT_OF_SYNC]: 'Yellow',
	[STATUS_MISSING_DATA]: 'Red',
	[STATUS_WITHOUT_ORGANIZATION]: 'Gray',
};
const GATEWAY_STAT_STATUS_VALUES = {
	[STATUS_OK]: 'Gateways OK',
	[STATUS_OUT_OF_SYNC]: 'Gateways out of rhythm',
	[STATUS_MISSING_DATA]: 'Not assigned',
};

const IMPORT_METHOD_CSV = 'csv';
const IMPORT_METHOD_FTP = 'ftp';
const IMPORT_METHOD_ROUTEML = 'routeml';

const GATEWAY_IMPORT_METHODS = [
	IMPORT_METHOD_CSV,
	IMPORT_METHOD_FTP,
	IMPORT_METHOD_ROUTEML,
];

const GATEWAY_IMPORT_METHOD_VALUES = {
	[IMPORT_METHOD_CSV]: 'Imported by CSV',
	[IMPORT_METHOD_FTP]: 'Imported by FTP',
	[IMPORT_METHOD_ROUTEML]: 'Imported by RouteML',
};

const STATUS_DELETED = 'deleted';
const STATUS_EXIST_AND_NEVER_SEEN = 'exist_and_never_seen';
const STATUS_EXIST_AND_SEEN = 'exist_and_seen';

const GATEWAY_STATUSES = [
	STATUS_DELETED,
	STATUS_EXIST_AND_NEVER_SEEN,
	STATUS_EXIST_AND_SEEN,
];

const GATEWAY_STATUS_VALUES = {
	[STATUS_DELETED]: 'Deleted',
	[STATUS_EXIST_AND_NEVER_SEEN]: 'Exists and never Seen in FTP',
	[STATUS_EXIST_AND_SEEN]: 'Exists and Seen in FTP',
};

export default {
	buildingAddress: 'building_address',
	GATEWAY_STAT_STATUSES,
	GATEWAY_STAT_STATUS_COLORS,
	GATEWAY_STAT_STATUS_VALUES,

	GATEWAY_IMPORT_METHODS,
	GATEWAY_IMPORT_METHOD_VALUES,

	GATEWAY_STATUSES,
	GATEWAY_STATUS_VALUES,
	getGatewayStatusFromValue: (value) => {
		const status = Object.keys(GATEWAY_STAT_STATUS_VALUES).find(
			(key) => GATEWAY_STAT_STATUS_VALUES[key] === value,
		);
		return parseInt(status, 10);
	},
};
