import React, {useEffect} from 'react';
// import moment from 'moment';
import fp from 'lodash/fp';
import {useLazyQuery, useMutation, useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {useTranslation} from 'react-i18next';
import {Route, Switch} from 'react-router-dom';
import useReactRouter from 'use-react-router';
import {MTableToolbar} from 'material-table';
import {makeStyles} from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import {useMe} from '../reducers/me';
import UserModel from '../model/user';
import BuildingModel from '../model/building';
import ErrorStatus from '../components/ErrorStatus';
import Table from '../components/Table';
import UserLink from '../components/User/Link';
import ManagerAssign from '../components/Uvis/ManagerAssign';
import UviBuilding from './UviBuilding';

const useStyles = makeStyles((theme) => ({
  tableToolbarWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  tableToolbar: {
    flexGrow: 1,
  },
  newItemButtonWrapper: {
    flex: 1,
  },
}));

const ASSIGN_MANAGER = gql`
  mutation addManagerToBuilding($userId: ID!, $buildingId: ID!) {
    addManagerToBuilding(userId: $userId, buildingId: $buildingId)
  }
`;

const REMOVE_MANAGER = gql`
  mutation removeManagerFromBuilding($userId: ID!, $buildingId: ID!) {
    removeManagerFromBuilding(userId: $userId, buildingId: $buildingId)
  }
`;

const GET_UVI_BUILDINGS = gql`
  query getUviBuildings($ids: [ID]) {
    uviBuildings(ids: $ids) {
      id
      street
      house_number
      city
      alternate_address
      postalcode
      eras_id
      managers {
        id
        email
        profile {
          id
          first_name
          last_name
          phone
        }
      }
      apartments {
        id
        permissions(current: true) {
          id
        }
      }
    }
  }
`;

const GET_UVI_BUILDINGS_ADMIN = gql`
  query getUviBuildings($ids: [ID]) {
    uviBuildings(ids: $ids) {
      id
      street
      house_number
      city
      alternate_address
      postalcode
      eras_id
      uvi_coverage
      public_permission {
        mention_in_statistic
      }
      managers {
        id
        email
        profile {
          id
          first_name
          last_name
          phone
        }
      }
      apartments {
        id
        permissions(current: true) {
          id
        }
      }
    }
  }
`;

const SCHEDULE_UVI_REPORTS = gql`
  mutation scheduleBulkSending($date: DateTime) {
    scheduleBulkSending(date: $date)
  }
`;

const SET_BUILDING_PUBLIC_PERMISSION = gql`
  mutation setBuildingPublicPermission(
    $building_id: ID!
    $mention_in_statistic: Boolean
  ) {
    setBuildingPublicPermission(
      building_id: $building_id
      mention_in_statistic: $mention_in_statistic
    ) {
      mention_in_statistic
    }
  }
`;

const GET_MANAGERS = gql`
  query getManagers {
    managers {
      id
      email
      role
      profile {
        id
        first_name
        last_name
        phone
      }
    }
  }
`;

/**
 * Prepare list of managers to be removed or added
 *
 * @param {Object[]} managers
 * @param {string} newManagerId
 * @returns {{toRemove:string[], toAdd:string[]}}
 */
const getPendingManagerOperations = (managers, newManagerId) => {
  const managerIds = managers.map((manager) => manager.id);
  const toAdd = [];
  const toRemove = managerIds.filter((id) => id !== newManagerId);
  if (newManagerId && !managerIds.includes(newManagerId)) {
    toAdd.push(newManagerId);
  }
  return {toRemove, toAdd};
};

const Content = ({match}) => {
  const [useMeData] = useMe();
  const me = useMeData || {status: null};
  const {loading, error, data, refetch} = useQuery(
    (UserModel.roleUser(me.role) && GET_UVI_BUILDINGS_ADMIN) ||
      GET_UVI_BUILDINGS,
    {},
  );
  const [removeManager, {loading: removeManagerLoading}] =
    useMutation(REMOVE_MANAGER);
  const [assignManager, {loading: assignManagerLoading}] =
    useMutation(ASSIGN_MANAGER);
  const [
    setBuildingPublicPermission,
    {loading: setBuildingPublicPermissionLoading},
  ] = useMutation(SET_BUILDING_PUBLIC_PERMISSION);

  const [scheduleBulkSending, {loading: scheduleBulkSendingLoading}] =
    useMutation(SCHEDULE_UVI_REPORTS);

  // All of this code is because there is a bug with queries
  // and material-table detailPanel. If we try to useQuery or
  // useLazyQuery to fetch data for the detailPanel, the panel
  // will close itself and it is needed a second click to open it.
  const [
    managerQuery,
    {loading: managersLoad, error: managersError, data: managersData},
  ] = useLazyQuery(GET_MANAGERS, {});
  useEffect(() => {
    if (UserModel.roleUser(me.role)) {
      managerQuery();
    }
  }, [me]);

  const isLoading =
    loading ||
    assignManagerLoading ||
    removeManagerLoading ||
    scheduleBulkSendingLoading ||
    setBuildingPublicPermissionLoading ||
    managersLoad;

  const {t} = useTranslation(['uvi', 'building']);

  const {history} = useReactRouter();
  const classes = useStyles();

  if (error || managersError) {
    return <ErrorStatus />;
  }
  return (
    <React.Fragment>
      <div>
        <Alert severity="info">{t('uvis:USE_UVIS_INFO')}</Alert>
      </div>
      <div>
        <Alert severity="warning">{t('uvis:USE_UVIS_INFO_ALERT')}</Alert>
      </div>
      <Table
        tableId="uvis"
        title=""
        isLoading={isLoading}
        columns={[
          {
            title: t('uvi:Manager'),
            field: 'managers',
            render: (building) =>
              building.managers.map((manager) => (
                <UserLink key={manager.id} user={manager} />
              )),
            editComponent: (editProps) => {
              return <ManagerAssign {...editProps} managersData={managersData} />;
            },
          },
          {
            title: t('uvis:Missing UVI receivers'),
            render: (building) => {
              const c = BuildingModel.countMissingTenants(building);
              if (c) {
                return <b>{c}</b>;
              }
              return c;
            },
            customSort: (building1, building2) =>
              BuildingModel.countMissingTenants(building1) -
              BuildingModel.countMissingTenants(building2),
            defaultSort: 'desc',
            editable: 'never',
          },
          {
            title: t('building:ID'),
            field: 'id',
            type: 'numeric',
            editable: 'never',
          },
          ...[
            UserModel.roleUser(me.role) && {
              title: t('uvis:UVI Coverage'),
              field: 'uvi_coverage',
              type: 'numeric',
              editable: 'never',
              render: (building) => {
                const txt =
                  Math.round((building.uvi_coverage || 0) * 100) + '%';
                if (building.uvi_coverage < 0.75) {
                  return <b>{txt}</b>;
                }
                return txt;
              },
            },
            UserModel.roleAdmin(me.role) && {
              title: t('uvis:Statistic'),
              field: 'public_permission.mention_in_statistic',
              type: 'boolean',
              editable: 'onUpdate',
            },
          ].filter((a) => a),

          {title: t('building:Street'), editable: 'never', field: 'street'},
          {
            title: t('building:Number'),
            editable: 'never',
            field: 'house_number',
          },
          {title: t('building:City'), editable: 'never', field: 'city'},
          {
            title: t('building:Zipcode'),
            editable: 'never',
            field: 'postalcode',
          },
          {title: t('building:ID1'), editable: 'never', field: 'eras_id'},
          {
            title: t('building:Alternate Address'),
            field: 'alternate_address',
            editable: 'never',
          },
        ]}
        data={loading ? [] : data.uviBuildings}
        options={{
          sorting: true,
          pageSize: 10,
        }}
        editable={
          UserModel.roleUser(me.role) && {
            onRowUpdate: async (newData, oldData) => {
              let processes = [];
              const {toRemove, toAdd} = getPendingManagerOperations(
                oldData.managers,
                newData.assignedManagerId,
              );
              if (toRemove.length > 0) {
                processes = processes.concat(
                  toRemove.map((userId) =>
                    removeManager({
                      variables: {
                        userId,
                        buildingId: oldData.id,
                      },
                    }),
                  ),
                );
              }
              if (toAdd.length > 0) {
                processes = processes.concat(
                  toAdd.map((userId) =>
                    assignManager({
                      variables: {
                        userId: userId,
                        buildingId: oldData.id,
                      },
                    }),
                  ),
                );
              }
              if (
                UserModel.roleAdmin(me.role) &&
                fp.get('public_permission.mention_in_statistic', newData) !==
                  fp.get('public_permission.mention_in_statistic', oldData)
              ) {
                processes.push(
                  setBuildingPublicPermission({
                    variables: {
                      building_id: newData.id,
                      mention_in_statistic: !!fp.get(
                        'public_permission.mention_in_statistic',
                        newData,
                      ),
                    },
                  }),
                );
              }
              await Promise.all(processes);
              refetch();
            },
          }
        }
        actions={[
          {
            icon: AllInboxIcon,
            tooltip: 'Schedule send Uvis',
            isFreeAction: true,
            hidden: !UserModel.roleAdmin(me.role),
            onClick: () => {
              scheduleBulkSending({variables: {}});
            },
          },
        ]}
        components={{
          Toolbar: (props) => (
            <div className={classes.tableToolbarWrapper}>
              <MTableToolbar
                {...props}
                classes={{root: classes.tableToolbar}}
              />
            </div>
          ),
        }}
        onRowClick={(event, rowData) => {
          history.push(`${match.path}/${rowData.id}`);
        }}
      />
    </React.Fragment>
  );
};

export default function Uvis({match}) {
  return (
    <Switch>
      <Route exact path={match.path} component={Content} />
      <Route path={`${match.path}/:id`} component={UviBuilding} />
    </Switch>
  );
}
