import React, {useState} from 'react';
import fp from 'lodash/fp';
import Alert from '@material-ui/lab/Alert';
import {useTranslation} from 'react-i18next';
import gql from 'graphql-tag';
import {useQuery} from '@apollo/react-hooks';
import MaterialTable from 'material-table';
import Link from '../../../components/Link';
import {makeStyles} from '@material-ui/core/styles';

import ReportError from '../../../model/report-error';

const GET_SENSORS = gql`
  query getSensors($ids: [ID!]) {
    sensors(ids: $ids) {
      id
      name
      eras_id
      created_at
      serial_number
      building {
        id
        eras_id
        street
        house_number
        city
      }
      apartment {
        id
        eras_number
      }
    }
  }
`;
const ConflictDetails = ({error}) => {
  const {serialNumber, conflictedSensors} = error.details;
  const conflictIds = conflictedSensors.map((s) => s.id);
  const {
    loading,
    error: isError,
    data,
  } = useQuery(GET_SENSORS, {
    variables: {
      ids: conflictIds,
    },
  });
  const {t} = useTranslation();

  if (isError) {
    return <div>{t('errors:Failed to load the data')}</div>;
  }
  return (
    <MaterialTable
      options={{search: false, paging: false, sorting: false}}
      title={`${t('building:Serial Number')} ${serialNumber}`}
      isLoading={loading}
      data={loading ? [] : data.sensors}
      columns={[
        {
          title: t('building:ID'),
          field: 'id',
        },
        {
          title: t('building:ID1'),
          field: 'eras_id',
        },
        {
          title: t('building:Name'),
          field: 'name',
        },
        {
          title: t('building:Serial Number'),
          field: 'serial_number',
        },
        {
          title: `${t('report:Building')}|${t('building:ID')}`,
          field: 'building.id',
          render: (item) => (
            <Link to={`/buildings/${fp.get('building.id', item)}`}>
              {fp.get('building.id', item)}
            </Link>
          ),
        },
        {
          title: t('building:ID1'),
          field: 'building.eras_id',
        },

        {
          title: t('building:Street'),
          field: 'building.street',
        },
        {
          title: t('building:Number'),
          field: 'building.house_number',
        },
        {
          title: t('building:City'),
          field: 'building.city',
        },
        {
          title: t('user:Apartment'),
          field: 'apartment.eras_number',
        },
      ]}
    />
  );
};
ConflictDetails.propTypes = {
  error: ReportError.propType,
};

const useStyles = makeStyles((theme) => ({
  identicalError: {
    color: "#ffffff",
    backgroundColor: "#ffb6ae",
  },
}));

const ErrorSensorConflict = ({error}) => {
  const {t} = useTranslation('reportErrors');
  const [extended, setExtended] = useState(false);
  const {serialNumber, conflictedSensors} = error.details;
  const conflictErasIds = conflictedSensors.map((s) => s.eras_id).filter((id) => !!id) || [];
  const classes = useStyles();

  const toggle = (e) => {
    e.preventDefault();
    setExtended((oldState) => !oldState);
  };

  const isIdenticalSensorsError = error.type === ReportError.ERROR_CONFLICT_IDENTICAL_SENSORS;
  const className = isIdenticalSensorsError ? classes.identicalError : '';
  const alertText = t(isIdenticalSensorsError ? 'Identical sensors' : 'Duplicate serial number');
  const conflictErasIdsStr = conflictErasIds.length ? `(${conflictErasIds.join(', ')})` : '';
  
  return (
    <>
      <Alert title={error.message} severity="error" className={className}>
        {`${alertText} `}
        <Link href="#" onClick={toggle} underline="always">
          {serialNumber}
        </Link>
        {` ${conflictErasIdsStr}`}
      </Alert>
      {extended && <ConflictDetails error={error} />}
    </>
  );
};

ErrorSensorConflict.propTypes = {
  error: ReportError.propType,
};

export default ErrorSensorConflict;
