import React, { useState } from "react";
import {
  Grid,
  Button,
  Typography,
  Box,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Card from "./Card";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import BuildingModel from "../../model/building";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";

const GET_SENSOR_FAILS = gql`
  mutation getSensorFails {
    sensorFails {
      sensor {
        id
        serial_number
        building {
          id
          eras_id
        }
      }
      status {
        date
        error
        error_flags
        manufacturer_error_description
      }
      errorDescription
    }
  }
`;

const GET_DEVICES_NOT_SEEN_BEYOND_THRESHOLD = gql`
  mutation getDevicesNotSeenBeyondThreshold {
    sensorsNotSeenBeyondThreshold {
      id
      serial_number
      device_type
      last_seen
      building {
        eras_id
        street
        house_number
        city
        postalcode
      }
      manufacturer
    }
  }
`;


const GET_SENSORS_MISSING_AES = gql`
  mutation getSensorsMissingAes {
    sensorsMissingAes {
      id
      serial_number
      device_type
      building {
        eras_id
        street
        house_number
        city
        postalcode
      }
      manufacturer
    }
  }
`;

function isValidNumber(value) {
  return typeof value === 'number' && Number.isFinite(value);
}

export default function DeviceIssues({ data }) {
  const { t } = useTranslation(["dashboard"]);
  const [getSensorFails] = useMutation(GET_SENSOR_FAILS);
  const [sensorFailsLoading, setSensorFailsLoading] = useState(false);

  const [getDevicesNotSeenBeyondThreshold] = useMutation(
    GET_DEVICES_NOT_SEEN_BEYOND_THRESHOLD
  );
  const [devicesNotSeenBeyondThresholdLoading, setDevicesNotSeenBeyondThresholdLoading] = useState(false);

  const [getDevicesMissingAESKey] = useMutation(GET_SENSORS_MISSING_AES);
  const [devicesMissingAESKeyLoading, setDevicesMissingAESKeyLoading] = useState(false);

  const handleDownloadSensorFails = async () => {
    setSensorFailsLoading(true);
    try {
      const { data } = await getSensorFails();

      const formattedData = data.sensorFails.map(
        ({ sensor, building, status }) => ({
          "Date of Statistic": "",
          "Device Serial Number": sensor?.serial_number,
          "Status Date": status?.date,
          "Device Type": sensor?.device_type,
          "Building ERAS Number": building.eras_id,
          "Device Manufacturer": sensor?.manufacturer,
          "Status Error": status?.error,
          "Status Error Flag": status?.error_flags,
          "Status Manufacturer Error": status?.manufacturer_error_description,
        })
      );

      // Convert the data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(formattedData);

      // Create a new workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sensor Fails");

      // Generate a binary string representation of the workbook
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Convert the binary string to a Blob and trigger the download
      const dataBlob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(dataBlob, "DevicesWithErrorCode.xlsx");
    } catch (error) {
      console.error("Error fetching devices with error code:", error);
    } finally {
      setSensorFailsLoading(false);
    }
  };

  const handleDownloadDevicesNotSeenBeyondThreshold = async () => {
    setDevicesNotSeenBeyondThresholdLoading(true);
    try {
      const { data } = await getDevicesNotSeenBeyondThreshold();

      const formattedData = data.sensorsNotSeenBeyondThreshold.map(({ serial_number,device_type,last_seen,manufacturer, building }) => ({
        "Date of Statistic": "",
        "Device Serial Number": serial_number,
        "Device Type": device_type,
        "Last Seen": last_seen,
        "Device Manufacturer": manufacturer,
        "Building ERAS Number": building.eras_id,
        "Building Address": BuildingModel.fullAddress(
          building.street,
          building.house_number,
          building.city,
          building.postalcode
        ),
      }));

      // Convert the data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(formattedData);

      // Create a new workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Devices Not Seen Beyond Threshold");

      // Generate a binary string representation of the workbook
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Convert the binary string to a Blob and trigger the download
      const dataBlob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(dataBlob, "DevicesNotSeenBeyondThreshold.xlsx");
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setDevicesNotSeenBeyondThresholdLoading(false);
    }
  };

  const handleDownloadDevicesMissingAESKey = async () => {
    setDevicesMissingAESKeyLoading(true);
    try {
      const { data } = await getDevicesMissingAESKey();

      const formattedData = data.sensorsMissingAes.map(({ serial_number,device_type,last_seen,manufacturer, building }) => ({
        "Date of Statistic": "",
        "Device Serial Number": serial_number,
        "Device Type": device_type,
        "Last Seen": last_seen,
        "Device Manufacturer": manufacturer,
        "Building ERAS Number": building.eras_id,
        "Building Address": BuildingModel.fullAddress(
          building.street,
          building.house_number,
          building.city,
          building.postalcode
        ),
      }));

      // Convert the data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(formattedData);

      // Create a new workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Devices Missing AES Key");

      // Generate a binary string representation of the workbook
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Convert the binary string to a Blob and trigger the download
      const dataBlob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(dataBlob, "DevicesMissingAESKey.xlsx");
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setDevicesMissingAESKeyLoading(false);
    }
  };

  return (
    <Grid item xs={12} md={4}>
      <Card title={t("dashboard:Device Issues")}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          p={2}
        >
          <Box>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              {t("dashboard:Devices with Error Code")}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {t("dashboard:Number of Devices")}: {isValidNumber(data.sensor_fails_count) ? data.sensor_fails_count : 'N/A'}
            </Typography>
          </Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleDownloadSensorFails}
            endIcon={
              sensorFailsLoading ? <CircularProgress size={24} /> : <DownloadIcon />
            }
            disabled={sensorFailsLoading || !isValidNumber(data.sensor_fails_count) || data.sensor_fails_count === 0}
          >
            {sensorFailsLoading ? t("dashboard:loading") : t("dashboard:excel")}
          </Button>
        </Box>
        <Divider />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          p={2}
        >
          <Box>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              {t("dashboard:Devices not seen beyond treshold")}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {t("dashboard:Number of Devices")}: {isValidNumber(data.sensors_not_seen_beyound_threshold_count) ? data.sensors_not_seen_beyound_threshold_count : 'N/A'}
            </Typography>
          </Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleDownloadDevicesNotSeenBeyondThreshold}
            endIcon={
              devicesNotSeenBeyondThresholdLoading ? <CircularProgress size={24} /> : <DownloadIcon />
            }
            disabled={devicesNotSeenBeyondThresholdLoading || !isValidNumber(data.sensors_not_seen_beyound_threshold_count) || data.sensors_not_seen_beyound_threshold_count === 0}
          >
            {devicesNotSeenBeyondThresholdLoading ? t("dashboard:loading") : t("dashboard:excel")}
          </Button>
        </Box>
        <Divider />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <Box>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              {t("dashboard:Devices missing AES Key")}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {t("dashboard:Number of Devices")}: {isValidNumber(data.sensors_missing_aes_count) ? data.sensors_missing_aes_count : 'N/A'}
            </Typography>
          </Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleDownloadDevicesMissingAESKey}
            endIcon={
              devicesMissingAESKeyLoading ? <CircularProgress size={24} /> : <DownloadIcon />
            }
            disabled={devicesMissingAESKeyLoading || !isValidNumber(data.sensors_missing_aes_count)  ||data.sensors_missing_aes_count === 0}
          >
            {devicesMissingAESKeyLoading ? t("dashboard:loading") : t("dashboard:excel")}
          </Button>
        </Box>
      </Card>
    </Grid>
  );
}
