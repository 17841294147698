import React, {useState} from 'react';
import moment from 'moment';
import fp from 'lodash/fp';
import {useTranslation} from 'react-i18next';
import {useApolloClient, useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {MTableToolbar} from 'material-table';
import {DatePicker} from '@material-ui/pickers';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from '@material-ui/core/styles';

import Table from './Table';
import {TelegramDetailPanel} from './TelegramDetailPanel';

const GET_TELEGRAMS = gql`
  query getGatewayTelegrams(
    $gatewayId: ID!
    $capturedStartDate: DateTime!
    $capturedEndDate: DateTime!
  ) {
    gatewayTelegrams(
      gatewayId: $gatewayId
      capturedStartDate: $capturedStartDate
      capturedEndDate: $capturedEndDate
    ) {
      id
      sensor_serial_number
      is_decrypted
      has_measurement
      captured_at
      header {
        device_text
        manufacturer
      }
    }
  }
`;

const GET_TELEGRAM_BY_ID = gql`
  query getTelegram($id: ID!) {
    telegram(id: $id) {
      id
      content
      decrypted
      header {
        L
        C
        CI
        serial
        manufacturer
        status
        version
        accNr
        decryption
        device
        device_text
        configuration
      }
      errorDescription
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  toolbarWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableToolbarWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  datePickerWrapper: {
    flex: 1,
  },
  addSensorsButton: {
    margin: theme.spacing(1),
  },
  downloadSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const TelegramsDialog = ({gateway, onClose}) => {
  const {t} = useTranslation(['gateway']);
  const [selectedDate, setSelectedDate] = useState(moment());
  const classes = useStyles();
  const tableRef = React.useRef(null);
  const client = useApolloClient();
  const [telegramsWithContent, setTelegramsWithContent] = useState({});
  const {loading, error, data} = useQuery(GET_TELEGRAMS, {
    variables: {
      gatewayId: gateway.id,
      capturedStartDate: selectedDate.startOf('month').toISOString(),
      capturedEndDate: selectedDate.endOf('month').toISOString(),
    },
  });

  if (error) {
    console.error(error);
  }

  const telegrams = fp.get('gatewayTelegrams', data) || [];

  const onDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <Dialog
      fullScreen
      open={!!gateway}
      onClose={onClose}
      TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {telegrams.length} {t('gateway:Telegrams for gateway')} {gateway.id}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box m={2}>
        {error ? (
          <div>Error</div>
        ) : (
          <Table
            tableRef={tableRef}
            tableId={'telegrams-dialog'}
            title=""
            columns={[
              {
                title: t('gateway:Sensor serial number'),
                field: 'sensor_serial_number',
              },
              {
                title: t('gateway:Captured at'),
                field: 'captured_at',
                render: (rowData) => {
                  return moment.utc(rowData.captured_at).format('LL');
                },
                filtering: false,
              },
              {
                title: t('gateway:Manufacturer'),
                field: 'header.manufacturer',
              },
              {
                title: t('gateway:Device type'),
                field: 'header.device_text',
              },
              {
                title: t('gateway:Telegram decrypted'),
                field: 'is_decrypted',
                type: 'boolean',
              },
              {
                title: t('gateway:Has measurement'),
                field: 'has_measurement',
                type: 'boolean',
              },
            ]}
            options={{
              toolbar: true,
              filtering: true,
              pageSize: 10,
            }}
            isLoading={loading}
            data={telegrams}
            components={{
              Toolbar: (props) => (
                <div className={classes.tableToolbarWrapper}>
                  <Box
                    ml={2}
                    mt={4}
                    mb={2}
                    className={classes.datePickerWrapper}>
                    <DatePicker
                      variant="inline"
                      openTo="month"
                      views={['year', 'month']}
                      label={t('gateway:Year and month')}
                      value={selectedDate}
                      onChange={onDateChange}
                    />
                  </Box>
                  <MTableToolbar
                    {...props}
                    classes={{root: classes.tableToolbar}}
                  />
                </div>
              ),
            }}
            onRowClick={async (_, rowData) => {
              // All of this code is because there is a bug with queries
              // and material-table detailPanel. If we try to useQuery or
              // useLazyQuery to fetch data for the detailPanel, the panel
              // will close itself and it is needed a second click to open it.
              const telegramId = rowData.id;
              let telegramData = telegramsWithContent[telegramId];

              if (!telegramData) {
                const {data} = await client.query({
                  query: GET_TELEGRAM_BY_ID,
                  variables: {id: telegramId},
                  fetchPolicy: 'network-only',
                });
                setTelegramsWithContent({
                  ...telegramsWithContent,
                  [telegramId]: data.telegram,
                });
                telegramData = data.telegram;
              }

              const sortedDataIndex = tableRef.current.dataManager.sortedData.findIndex(
                (row) => row.id === telegramId
              );
              tableRef.current.onToggleDetailPanel(
                [sortedDataIndex],
                () => (
                  <Box m={6}>
                    <TelegramDetailPanel telegram={telegramData} t={t} />
                  </Box>
                ),
              );
            }}
            detailPanel={[
              {
                disabled: true,
                icon: () => null,
                render: (rowData) => {
                  return null;
                },
              },
            ]}
          />
        )}
      </Box>
    </Dialog>
  );
};
