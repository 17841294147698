import config from '../config';
import fetch from './fetch';

const makeCsvAnchor = (res, filename) => {
  const delimiter = ';';
  let data = [];

  data.push(res.header.join(delimiter));

  data = res.rows.reduce((acc, items) => {
    acc.push(items.join(delimiter));
    return acc;
  }, data);

  const anchor = document.createElement('a');
  document.body.appendChild(anchor);

  anchor.setAttribute(
    'href',
    'data:attachment/csv;charset=utf-8,' + encodeURI(data.join('\n')),
  );
  anchor.setAttribute('target', '_self');
  anchor.setAttribute('download', filename);
  anchor.click();
};

export default async function download(data, filename, path) {
  try {
    let json;
    if (path) {
      const response = await fetch(config.apiUri + path, {
        method: 'GET',
      });
      if (!response) {
        alert('Something went wrong. ERROR 638');
        return;
      }

      if (!response.ok) {
        alert('Something went wrong. ERROR 643');
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
      }
      json = await response.json();
    } else {
      json = data;
    }

    makeCsvAnchor(json, filename);
  } catch (err) {
    console.log('ERROR 648', err);
    alert(err);
  }
};
