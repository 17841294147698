// @format
import React, {useState} from 'react';
import moment from 'moment';
import fp from 'lodash/fp';
import useForm from 'react-hook-form';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';
import {useQuery, useLazyQuery, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {Route} from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import {KeyboardDatePicker} from '@material-ui/pickers';

import Select from '../components/Select';
import BuildingModel from '../model/building';

const GET_USER = gql`
  query getUser($id: ID!) {
    users(ids: [$id]) {
      id
      email
      role
      profile {
        id
        first_name
        last_name
        phone
        street1
        street2
        postal_code
        city
      }
      organization {
        id
        name
      }
      apartmentPermission {
        id
        start_date
        end_date
        apartment {
          id
          location
          eras_number
          building {
            id
            street
            house_number
            city
            postalcode
          }
        }
      }
    }
  }
`;

const GET_ORGANIZATIONS = gql`
  query getOrganizations {
    organizations {
      id
      name
    }
  }
`;

const GET_BUILDINGS = gql`
  query getBuildings {
    buildings {
      id
      eras_id
      street
      house_number
      city
      postalcode
    }
  }
`;

const GET_BUILDING_APARTMENTS = gql`
  query getBuildingApartments($buildingId: ID!) {
    buildings(ids: [$buildingId]) {
      id
      eras_id
      apartments {
        id
        eras_number
        location
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $email: String!
    $password: String
    $firstName: String
    $lastName: String
    $phone: String
    $street1: String
    $street2: String
    $postal_code: String
    $city: String
    $role: Int!
    $organizationId: ID!
  ) {
    updateUser(
      id: $id
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      street1: $street1
      street2: $street2
      postal_code: $postal_code
      city: $city
      role: $role
      organizationId: $organizationId
    ) {
      id
      email
      role
      organization {
        id
        name
      }
      profile {
        id
        first_name
        last_name
        phone
      }
    }
  }
`;

const ADD_APARTMENT_TO_USER = gql`
  mutation addApartmentToUser(
    $userId: ID!
    $apartmentId: ID!
    $startDate: DateTime!
    $endDate: DateTime
  ) {
    addApartmentToUser(
      userId: $userId
      apartmentId: $apartmentId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      apartment {
        id
        building {
          id
        }
      }
    }
  }
`;

const UserInfoForm = ({user, updateUser, isCustomerForm}) => {
  const {t} = useTranslation(['user']);
  const {enqueueSnackbar} = useSnackbar();
  const defaultValues = {
    email: fp.get('email', user),
    firstName: fp.get('profile.first_name', user) || '',
    lastName: fp.get('profile.last_name', user) || '',
    phone: fp.get('profile.phone', user) || '',
    street1: fp.get('profile.street1', user) || '',
    street2: fp.get('profile.street2', user) || '',
    postal_code: fp.get('profile.postal_code', user) || '',
    city: fp.get('profile.city', user) || '',
    password: '',
    role: fp.get('role', user),
    organization: fp.get('organization.id', user),
  };
  const {register, setValue, watch, handleSubmit, errors} = useForm({
    defaultValues,
  });
  const {
    data: organizationsData,
    loading: organizationsLoading,
    error: organizationsError,
  } = useQuery(GET_ORGANIZATIONS, {});
  const roles = t('user:roles');
  const organizationValue = watch('organization');

  if (organizationsLoading) {
    return <CircularProgress />;
  }

  if (organizationsError) {
    console.error(organizationsError);
    return <div>Error</div>;
  }

  const onRoleChange = ({value}) => {
    setValue('role', value);
  };

  const onOrganizationChange = ({value}) => {
    setValue('organization', value);
  };

  const onSubmit = async (data) => {
    try {
      await updateUser({
        variables: {
          id: user.id,
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          phone: data.phone,
          street1: data.street1,
          street2: data.street2,
          postal_code: data.postal_code,
          city: data.city,
          role: Number(data.role),
          organizationId: data.organization,
          ...(data.password && {password: data.password}),
        },
      });
    } catch (err) {
      enqueueSnackbar(t('user:Could not save user'), {variant: 'error'});
      console.error(err);
      return;
    }

    enqueueSnackbar(t('user:User info saved'), {variant: 'success'});
  };

  const rolesOptions = Object.keys(roles).map((roleId) => {
    return {
      label: roles[roleId],
      value: roleId,
    };
  });
  const organizations = organizationsData.organizations.map((organization) => {
    return {
      label: organization.name,
      value: organization.id,
    };
  });

  register({name: 'role', type: 'custom'}, {required: t('user:Choose a role')});
  register(
    {name: 'organization', type: 'custom'},
    {required: t('user:Choose an organization')},
  );

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <TextField
        fullWidth
        id="firstName"
        name="firstName"
        label={t('user:First Name')}
        margin="normal"
        inputRef={register}
        defaultValue={defaultValues.firstName}
      />
      {errors.firstName && errors.firstName.message}
      <TextField
        fullWidth
        id="lastName"
        name="lastName"
        label={t('user:Last Name')}
        margin="normal"
        inputRef={register}
        defaultValue={defaultValues.lastName}
      />
      {errors.lastName && errors.lastName.message}
      <Select
        inputId="organization-id"
        placeholder={t('user:Choose an organization')}
        options={organizations}
        onChange={onOrganizationChange}
        defaultValue={
          organizations.length > 0 && defaultValues.organization
            ? organizations.find(
                (organization) =>
                  organization.value === defaultValues.organization,
              )
            : null
        }
        value={fp.find({value: organizationValue}, organizations)}
        TextFieldProps={{
          required: true,
          label: t('user:Organization'),
          margin: 'normal',
          name: 'organization',
        }}
      />
      {errors.organization && errors.organization.message}
      {!isCustomerForm ? (
        <React.Fragment>
          <Select
            inputId="role-id"
            placeholder={t('user:Role')}
            options={rolesOptions}
            onChange={onRoleChange}
            defaultValue={rolesOptions.find((role) => {
              return Number(role.value) === user.role;
            })}
            TextFieldProps={{
              required: true,
              label: t('user:Role'),
              margin: 'normal',
              name: 'role',
            }}
          />
          {errors.role && errors.role.message}
        </React.Fragment>
      ) : null}
      <TextField
        fullWidth
        id="email"
        name="email"
        label={t('user:E-mail')}
        margin="normal"
        inputRef={register}
        defaultValue={defaultValues.email}
      />
      {errors.email && errors.email.message}
      <TextField
        fullWidth
        id="phone"
        name="phone"
        label={t('user:Phone')}
        margin="normal"
        inputRef={register}
        defaultValue={defaultValues.phone}
      />
      {errors.phone && errors.phone.message}
      <TextField
        fullWidth
        id="street1"
        name="street1"
        label={t('user:Street 1')}
        margin="normal"
        inputRef={register}
        defaultValue={defaultValues.street1}
      />
      {errors.street1 && errors.street1.message}
      <TextField
        fullWidth
        id="street2"
        name="street2"
        label={t('user:Street 2')}
        margin="normal"
        inputRef={register}
        defaultValue={defaultValues.street2}
      />
      {errors.street1 && errors.street2.message}
      <TextField
        fullWidth
        id="postal_code"
        name="postal_code"
        label={t('user:ZIP code')}
        margin="normal"
        inputRef={register}
        defaultValue={defaultValues.postal_code}
      />
      {errors.postal_code && errors.postal_code.message}
      <TextField
        fullWidth
        id="city"
        name="city"
        label={t('user:City')}
        margin="normal"
        inputRef={register}
        defaultValue={defaultValues.city}
      />
      {errors.city && errors.city.message}
      <TextField
        fullWidth
        type="password"
        id="password"
        name="password"
        label={t('user:New password')}
        margin="normal"
        inputRef={register}
      />
      {errors.password && errors.password.message}
      <Button type="submit" variant="contained" color="primary">
        {t('user:Save')}
      </Button>
    </form>
  );
};

const UserApartmentForm = ({user}) => {
  const {t} = useTranslation(['user']);
  const {enqueueSnackbar} = useSnackbar();
  const [loadedDefaultBuilding, setLoadedDefaultBuilding] = useState(false);
  const defaultValues = {
    apartment: fp.get('apartmentPermission.apartment.id', user) || null,
    building: fp.get('apartmentPermission.apartment.building.id', user) || null,
    startDate: fp.get('apartmentPermission.start_date', user) || null,
    endDate: fp.get('apartmentPermission.end_date', user) || null,
  };
  const {register, setValue, watch, handleSubmit, errors} = useForm({
    defaultValues,
  });
  const [getBuildingApartments, {data: apartmentsData}] = useLazyQuery(
    GET_BUILDING_APARTMENTS,
  );
  const {
    loading: buildingsLoading,
    error: buildingsError,
    data: buildingsData,
  } = useQuery(GET_BUILDINGS, {});
  const [addApartmentToUser, {loading: addApartmentToUserLoading}] =
    useMutation(ADD_APARTMENT_TO_USER);

  const {
    apartment: apartmentValue,
    building: buildingValue,
    startDate: startDateValue,
    endDate: endDateValue
  } = watch(['apartment', 'building', 'startDate', 'endDate']);

  if (buildingsLoading) {
    return <CircularProgress />;
  }

  if (buildingsError) {
    console.error(buildingsError);
    return <div>Error</div>;
  }

  const buildings = buildingsLoading
    ? []
    : buildingsData.buildings.map((building) => {
        return {
          label: BuildingModel.label(building),
          value: building.id,
        };
      });
  const apartments = (
    fp.get('buildings[0].apartments', apartmentsData) || []
  ).map((apartment) => {
    return {
      label: `${apartment.eras_number} - ${apartment.location}`,
      value: apartment.id,
    };
  });

  const onBuildingChange = ({value}) => {
    setValue('building', value);
    setValue('apartment', null);
    getBuildingApartments({variables: {buildingId: value}});
  };

  const onApartmentChange = ({value}) => {
    setValue('apartment', value);
  };

  const onStartDateChange = (value) => {
    setValue(
      'startDate',
      value && moment.utc(value.format('YYYY-MM-DD')).toISOString(),
    );
  };

  const onEndDateChange = (value) => {
    setValue(
      'endDate',
      value && moment.utc(value.format('YYYY-MM-DD')).toISOString(),
    );
  };

  const onSubmit = async (data) => {
    try {
      await addApartmentToUser({
        variables: {
          userId: user.id,
          apartmentId: data.apartment,
          startDate: data.startDate,
          endDate: data.endDate,
        },
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t('user:Could not add the apartment'), {
        variant: 'error',
      });
      return;
    }

    enqueueSnackbar(t('user:Apartment added to user'), {variant: 'success'});
  };

  if (
    user &&
    user.apartmentPermission &&
    !loadedDefaultBuilding &&
    apartments.length === 0
  ) {
    setLoadedDefaultBuilding(true);
    getBuildingApartments({
      variables: {
        buildingId: user.apartmentPermission.apartment.building.id,
      },
    });
    return <CircularProgress />;
  }

  register(
    {name: 'building', type: 'custom'},
    {required: t('user:Choose a building')},
  );
  register(
    {name: 'apartment', type: 'custom'},
    {required: t('user:Choose an apartment')},
  );
  register(
    {name: 'startDate', type: 'custom'},
    {required: t('user:Change date')},
  );
  register(
    {name: 'endDate', type: 'custom'},
    {required: t('user:Change date')},
  );

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Select
        menuPlacement="top"
        inputId="building-id"
        placeholder={t('user:Choose a building')}
        options={buildings}
        onChange={onBuildingChange}
        value={fp.find({value: buildingValue}, buildings)}
        defaultValue={
          buildings.length > 0 && defaultValues.building
            ? buildings.find(
                (building) => building.value === defaultValues.building,
              )
            : null
        }
        TextFieldProps={{
          required: false,
          label: t('user:Building'),
          margin: 'normal',
          name: 'building',
        }}
      />
      {errors.building && errors.building.message}
      <Select
        menuPlacement="top"
        inputId="apartment-id"
        placeholder={t('user:Choose an apartment')}
        options={apartments}
        onChange={onApartmentChange}
        value={fp.find({value: apartmentValue}, apartments)}
        defaultValue={
          apartments.length > 0 && defaultValues.apartment
            ? apartments.find(
                (apartment) => apartment.value === defaultValues.apartment,
              )
            : null
        }
        TextFieldProps={{
          required: false,
          label: t('user:Apartment'),
          margin: 'normal',
          name: 'apartment',
        }}
      />
      {errors.apartment && errors.apartment.message}
      <KeyboardDatePicker
        fullWidth
        id="startDate"
        name="startDate"
        margin="normal"
        label={t('user:Start date')}
        format="DD.MM.YYYY"
        value={startDateValue}
        onChange={onStartDateChange}
        KeyboardButtonProps={{
          'aria-label': t('user:Change date'),
        }}
      />
      {errors.startDate && errors.startDate.message}
      <KeyboardDatePicker
        fullWidth
        id="endDate"
        name="endDate"
        margin="normal"
        label={t('user:End date')}
        format="DD.MM.YYYY"
        value={endDateValue}
        onChange={onEndDateChange}
        KeyboardButtonProps={{
          'aria-label': t('user:Change date'),
        }}
      />
      {errors.endDate && errors.endDate.message}
      <Button
        disabled={addApartmentToUserLoading}
        type="submit"
        variant="contained"
        color="primary">
        {t('user:Save')}
      </Button>
    </form>
  );
};

const Content = ({match}) => {
  const {t} = useTranslation(['user']);
  const {loading, error, data} = useQuery(GET_USER, {
    variables: {
      id: match.params.id,
    },
  });
  const [updateUser] = useMutation(UPDATE_USER);
  const user = data ? fp.first(data.users) : null;
  const isLoading = loading;

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    console.error(error);
    return <div>Error</div>;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <h2>{t('user:Details')}</h2>
        <UserInfoForm user={user} updateUser={updateUser} />
        {user.role === 1 && (
          <React.Fragment>
            <h2>{t('user:Apartment')}</h2>
            <UserApartmentForm user={user} />
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
};

export {UserInfoForm, UserApartmentForm};
export default function User({match}) {
  return (
    <React.Fragment>
      <Route exact path={match.path} component={Content} />
    </React.Fragment>
  );
}
